import { useEffect } from 'react'
import { isChrome, isMobile, isOpera, isEdge, browserName } from 'react-device-detect'
import { amplitudeSendData, amplitudeInitialize } from 'util/amplitude'
import {
  Text,
  EdgeImage,
  Container,
  OperaImage,
  BraveImage,
  ChromeImage,
  BrowserImage,
  PageContainer,
  CenterContainer,
  BrowserContainer
} from './components'

interface BrowserSupportProps {
  children: any
}

const isBrave = browserName === 'Brave'

const BrowserSupport = ({ children }: BrowserSupportProps) => {
  useEffect(() => {
    amplitudeInitialize()
  }, [])

  useEffect(() => {
    if ((!isChrome && !isOpera && !isEdge && !isBrave) || isMobile) {
      amplitudeSendData('User lands on sorry your browser is not compatible screen', { browser: browserName })
    }
  }, [])

  if ((isChrome || isOpera || isEdge || isBrave) && !isMobile) {
    return children
  }

  return (
    <PageContainer>
      <CenterContainer page>
        <BrowserImage />

        <Container>
          <Text browser-not-supported-title>Sorry, your mobile browser is not compatible</Text>

          <Text browser-not-supported-description>
            To use StormX Claiming you must use a desktop web3 browser. Below are some of the most popular options:
          </Text>

          <Text supported-browsers>Supported browsers</Text>

          <CenterContainer browsers>
            <BrowserContainer onClick={() => window.open('https://www.google.com/chrome/')}>
              <ChromeImage />
              <Text browser-name>Chrome</Text>
            </BrowserContainer>

            <BrowserContainer onClick={() => window.open('https://brave.com/download/')}>
              <BraveImage />
              <Text browser-name>Brave</Text>
            </BrowserContainer>

            <BrowserContainer onClick={() => window.open('https://www.opera.com/pt/download')}>
              <OperaImage />
              <Text browser-name>Opera</Text>
            </BrowserContainer>

            <BrowserContainer onClick={() => window.open('https://www.microsoft.com/en-us/edge')}>
              <EdgeImage />
              <Text browser-name>Edge</Text>
            </BrowserContainer>
          </CenterContainer>
        </Container>
      </CenterContainer>
    </PageContainer>
  )
}

export default BrowserSupport
