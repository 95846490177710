import Header from 'components/header'
import Footer from 'components/footer'
import Button from 'components/button'
import ModalAccount from 'components/modal-account'
import ModalConnect from 'components/modal-connect'
import ModalSuccess from 'components/modal-success'
import ModalNetworkError from 'components/modal-network-error'
import ModalError from 'components/modal-error'
import Config from 'state/config/model'
import ContractABI from 'misc/claimContractAbi.json'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { isAvailable } from 'util/claim'
import { amplitudeSendData } from 'util/amplitude'
import { claimAddress, startDate, endDate, stakingUrl } from 'util/config'
import { ethers } from 'ethers'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'
import { useEthers } from '@usedapp/core'
import { actions as configActions } from 'state/config'
import { useState, useCallback, Fragment } from 'react'
import {
  Text,
  Card,
  Check1,
  Check2,
  Check3,
  Check4,
  Check5,
  Check6,
  StepItem,
  TokenLogo,
  RowContainer,
  CardContainer,
  StepContainer,
  CenterContainer,
  ButtonContainer,
  ConnectContainer,
  ConsentContainer
} from './components'

interface HomeProps {
  wallet?: string
  walletInfo: object
  isNetworkWrong: boolean
  hasClaimed: null | boolean
  walletInfoLoading: boolean
  setError: (_error: string) => void
  setClaimed: (_claimed: boolean) => void
  setDisplayError: (_show: boolean) => void
  setDisplaySuccess: (_show: boolean) => void
  setDisplayConnectWallet: (_show: boolean) => void
}

const Home = ({
  wallet,
  setError,
  walletInfo,
  setClaimed,
  hasClaimed,
  isNetworkWrong,
  setDisplayError,
  setDisplaySuccess,
  walletInfoLoading,
  setDisplayConnectWallet
}: HomeProps) => {
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState(false)
  const { deactivate } = useEthers()

  const getContract = useCallback(() => {
    return new ethers.Contract(claimAddress, ContractABI)
  }, [])

  const claimTokens = async () => {
    try {
      setLoading(true)
      // @ts-ignore
      const { index, amount, proof } = walletInfo.claim
      const transaction = await getContract().claim(index, wallet, amount, proof)
      await transaction.wait()

      setLoading(false)
      setClaimed(true)
      setDisplaySuccess(true)
    } catch (e) {
      if (e instanceof Error) {
        amplitudeSendData('Claim error', { error: e.message })
        setError(e.message)
        setDisplayError(true)
      }

      setLoading(false)
    }
  }

  return (
    <Fragment>
      <Header />

      <Text claim>Claim your Governance Tokens</Text>
      <Text description add-margin-top>
        Thank you for being a valued StormX user.
      </Text>
      <Text description>
        We would like to introduce you to the StormX Governance Token, Athens (ATH). Named after Athens, Greece, home to
        the first known democracy.
      </Text>

      <Text description add-margin-top>
        We firmly believe that democracy and decentralization are intrinsically linked. That's why we created ATH to
        allow users like yourself to have input on both company & community-raised votes.
      </Text>

      <Text description add-margin-bottom add-margin-top>
        To claim your tokens, please connect the wallet you use to interact with the StormX ecosystem (Shopping,
        staking, play, etc.). Please note tokens will be available to claim for 21 days from launch, and once closed,
        all remaining ATH will be returned to the treasury.
      </Text>

      {walletInfoLoading ? (
        <Fragment>
          <CardContainer eligible>
            <Card>
              <RowContainer>
                <CenterContainer>
                  <TokenLogo />

                  <RowContainer column>
                    {/*// @ts-ignore */}
                    <SkeletonTheme color='#202020' highlightColor='#444'>
                      <Skeleton width={200} height={16} />
                      <Skeleton width={320} height={55} style={{ marginTop: 10 }} />
                    </SkeletonTheme>
                  </RowContainer>
                </CenterContainer>

                <ButtonContainer eligible>
                  <Button
                    text='Claim my tokens'
                    purple={true}
                    loading={true}
                    disabled={true}
                    onClick={() => undefined}
                  />
                </ButtonContainer>
              </RowContainer>
            </Card>
          </CardContainer>

          <Text how lock connected>
            Available from {startDate.toLocaleString()} UTC to {endDate.toLocaleString()} UTC
          </Text>
        </Fragment>
      ) : walletInfo && wallet ? (
        <Fragment>
          <CardContainer eligible>
            <Card>
              <RowContainer>
                <CenterContainer>
                  <TokenLogo />

                  <RowContainer column>
                    {!isEmpty(walletInfo) ? (
                      <Fragment>
                        <Text eligible>You are eligible for:</Text>
                        {/*// @ts-ignore */}
                        <Text amount>{ethers.utils.formatUnits(walletInfo.claim.amount, 18)} tokens</Text>
                      </Fragment>
                    ) : (
                      <Text eligible>Your wallet is not eligible.</Text>
                    )}
                  </RowContainer>
                </CenterContainer>

                <ButtonContainer eligible>
                  <Button
                    text={hasClaimed !== null && hasClaimed ? 'Already claimed' : 'Claim my tokens'}
                    purple={true}
                    loading={loading}
                    disabled={!checked || hasClaimed === null || hasClaimed || isEmpty(walletInfo) || !isAvailable()}
                    onClick={() => {
                      if (!isNetworkWrong && !hasClaimed && !isEmpty(walletInfo) && isAvailable()) {
                        /*// @ts-ignore */
                        amplitudeSendData('Clicked claim my tokens button', {
                          /*// @ts-ignore */
                          amount: ethers.utils.formatUnits(walletInfo.claim.amount, 18),
                          consent: checked
                        })
                        claimTokens()
                      }
                    }}
                  />

                  {((hasClaimed !== null && hasClaimed) || isEmpty(walletInfo)) && (
                    <ConnectContainer
                      onClick={() => {
                        amplitudeSendData('Clicked connect another wallet button')
                        deactivate()
                        setDisplayConnectWallet(true)
                      }}
                    >
                      <Text connect-different-wallet>Connect another wallet</Text>
                    </ConnectContainer>
                  )}
                </ButtonContainer>
              </RowContainer>

              {!isEmpty(walletInfo) && (
                <RowContainer consent>
                  <input name='consent' type='checkbox' checked={checked} onChange={() => setChecked(!checked)} />

                  <ConsentContainer>
                    <Text>
                      <Text consent>
                        By connecting your wallet, you agree to StormX's{' '}
                        <Text consent underline terms onClick={() => window.open('https://stormx.io/terms-of-use')}>
                          Terms of Use
                        </Text>{' '}
                        and acknowledge that Athens tokens are used for governance only with no promise of financial
                        value.
                      </Text>
                    </Text>
                  </ConsentContainer>
                </RowContainer>
              )}
            </Card>
          </CardContainer>

          <Text how lock connected>
            Available from {startDate.toLocaleString()} UTC to {endDate.toLocaleString()} UTC
          </Text>
        </Fragment>
      ) : (
        <Fragment>
          <ButtonContainer>
            <Button
              text='Connect wallet'
              disabled={!isAvailable()}
              purple={true}
              onClick={() => {
                if (isAvailable()) {
                  amplitudeSendData('Clicked connect wallet button')
                  setDisplayConnectWallet(true)
                }
              }}
            />
          </ButtonContainer>

          <Text how lock>
            Available from {startDate.toLocaleString()} UTC to {endDate.toLocaleString()} UTC
          </Text>

          {/* <Text claim-video underline onClick={() => window.open("https://youtu.be/vkK6yoVXY4E")}>
            See how claiming works
          </Text> */}
        </Fragment>
      )}

      <Text
        learn-more
        underline
        onClick={() => window.open('https://stormx.io/blog/governance-token-update-launch-and-claiming')}
      >
        Learn more on our blog
      </Text>

      <Text how>How are my tokens calculated?</Text>

      <CardContainer>
        <Card>
          <Text card>
            You are eligible for ATH tokens if you have interacted with the StormX ecosystem in the following ways. A
            snapshot of interactions were taken up until March 20th, 2022.
          </Text>

          <StepContainer>
            <StepItem>
              <Check1 />
              <Text>
                <Text card>Earned 1 STMX via </Text>
                <Text card underline onClick={() => window.open(stakingUrl)}>
                  staking
                </Text>
                <Text card>, you will receive 0.000127 ATH</Text>
              </Text>
            </StepItem>

            <StepItem>
              <Check2 />
              <Text card>Earned $1 via shopping on the base cashback reward, you will receive 0.416673 ATH</Text>
            </StepItem>

            <StepItem>
              <Check3 />
              <Text>
                <Text card>Casted 1 </Text>
                <Text card underline onClick={() => window.open('https://governance.stormx.io/')}>
                  governance vote
                </Text>
                <Text card>, you will receive 10.604113 ATH</Text>
              </Text>
            </StepItem>

            <StepItem>
              <Check4 />
              <Text card>
                Earned $1 via play, you will receive 0.091486 ATH for tasks completed since October 26th 2018. Excludes
                surveys and video rewards.
              </Text>
            </StepItem>

            <StepItem>
              <Check5 />
              <Text>
                <Text card>Held a </Text>
                <Text
                  card
                  underline
                  onClick={() => window.open('https://opensea.io/assets/0xb49c47e18c537635ed285e6688eb8d934ca5522c/6')}
                >
                  Stormy NFT
                </Text>
                <Text card>, you will receive 1.016949 ATH</Text>
              </Text>
            </StepItem>

            <StepItem>
              <Check6 />
              <Text>
                <Text card>Purchased 1 ETH worth of </Text>
                <Text card underline onClick={() => window.open('https://nft.stormx.io/')}>
                  Shrug NFTs
                </Text>
                <Text card>, you will receive 28.059396 ATH</Text>
              </Text>
            </StepItem>
          </StepContainer>
        </Card>
      </CardContainer>

      <Footer />

      <ModalAccount />
      <ModalConnect />
      <ModalNetworkError />
      <ModalError />
      <ModalSuccess />
    </Fragment>
  )
}

const enhancer = connect(
  (state: { config: Config }) => ({
    wallet: state.config.wallet,
    walletInfo: state.config.walletInfo,
    hasClaimed: state.config.hasClaimed,
    isNetworkWrong: state.config.isNetworkWrong,
    walletInfoLoading: state.config.walletInfoLoading
  }),
  {
    setError: configActions.setError,
    setClaimed: configActions.setClaimed,
    setDisplayError: configActions.setDisplayError,
    setDisplaySuccess: configActions.setDisplaySuccess,
    setDisplayConnectWallet: configActions.setDisplayConnectWallet
  }
)

export default enhancer(Home)
