const defaultHeaders = {
  'Content-Type': 'application/json'
}

export const setHeaders = async () => {
  return { ...defaultHeaders }
}

export async function checkStatus(response) {
  const data = await response.json()
  if (response.status !== 200) {
    const error = {
      ...data,
      correlationId: response.headers.get('X-Correlation-ID') || ''
    }

    throw error
  }

  return data
}
