import { handleActions } from 'redux-actions'
import { combineReducers } from 'redux'
import {
  setError,
  setWallet,
  setClaimed,
  getWalletInfo,
  getATHRate,
  setDisplayError,
  setDisplayAccount,
  setConnectionType,
  setDisplaySuccess,
  setDisplayNetworkError,
  setDisplayConnectWallet
} from './actions'

const walletInfo = handleActions(
  {
    [getWalletInfo.SUCCEEDED]: (state, { payload }) => payload
  },
  {}
)

const walletInfoLoading = handleActions(
  {
    [getWalletInfo.START]: () => true,
    [getWalletInfo.ENDED]: () => false
  },
  false
)

const athRate = handleActions(
  {
    [getATHRate.SUCCEEDED]: (state, { payload }) => payload
  },
  0
)

const isNetworkWrong = handleActions(
  {
    [setDisplayNetworkError.toString()]: (state, { payload }) => payload
  },
  false
)

const wallet = handleActions(
  {
    [setWallet.toString()]: (state, { payload }) => payload
  },
  ''
)

const connectionType = handleActions(
  {
    [setConnectionType.toString()]: (state, { payload }) => payload
  },
  ''
)

const displayConnectWalletModal = handleActions(
  {
    [setDisplayConnectWallet.toString()]: (state, { payload }) => payload
  },
  false
)

const displayAccountModal = handleActions(
  {
    [setDisplayAccount.toString()]: (state, { payload }) => payload
  },
  false
)

const error = handleActions(
  {
    [setError.toString()]: (state, { payload }) => payload
  },
  'false'
)

const displayErrorModal = handleActions(
  {
    [setDisplayError.toString()]: (state, { payload }) => payload
  },
  false
)

const displaySuccessModal = handleActions(
  {
    [setDisplaySuccess.toString()]: (state, { payload }) => payload
  },
  false
)

const hasClaimed = handleActions(
  {
    [setClaimed.toString()]: (state, { payload }) => payload
  },
  null
)

export default combineReducers({
  error,
  wallet,
  walletInfo,
  hasClaimed,
  isNetworkWrong,
  connectionType,
  walletInfoLoading,
  athRate,
  displayErrorModal,
  displayAccountModal,
  displaySuccessModal,
  displayConnectWalletModal
})
