import styled from 'styled-components'
import close from 'images/close.png'
import metamask from 'images/metamask.png'
import coinbase from 'images/coinbase.png'
import walletconnect from 'images/walletconnect.png'
import { withModifiers } from 'util/styles'

export const CloseIcon = styled.img.attrs({
  src: close
})`
  width: 32px;
  height: 32px;
  float: right;
  margin-top: -5px;
  margin-left: -32px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`

export const MetamaskIcon = styled.img.attrs({
  src: metamask
})`
  width: 30px;
  height: 28px;
`

export const WalletConnectIcon = styled.img.attrs({
  src: walletconnect
})`
  width: 33px;
  height: 20px;
`

export const CoinbaseIcon = styled.img.attrs({
  src: coinbase
})`
  width: 30px;
  height: 30px;
`

export const Text = styled.label`
  -webkit-font-smoothing: antialiased;
  margin-bottom: 0px;

  ${withModifiers('modal-title')`
    text-align: left;
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-family: Montserrat-ExtraBold;
    font-weight: 800;
  `};

  ${withModifiers('modal-description')`
    width: 100%;
    color: rgb(29, 29, 29);
    font-size: 20px;
    font-family: Montserrat-Bold;
    font-weight: bold;
    text-align: center;
    line-height: 30px;
  `};

  ${withModifiers('wallet')`
    color: rgb(255, 255, 255);
    font-size: 15px;
    font-family: Adelle-Regular;
    font-weight: normal;
    line-height: 22px;
    cursor: pointer;
    padding-top: 4px;
  `};

  ${withModifiers('terms')`
    margin-right: 3px;
  `};

  ${withModifiers('underline')`
    text-decoration-line: underline;
    cursor: pointer;
    margin-left: 3px;

    &:hover {
      opacity: 0.5;
    }
  `};
`

export const ButtonContainer = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding-top: 19px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: rgba(255, 255, 255, 0.12);
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    opacity: 0.5;
  }

  ${withModifiers('metamask')`
    margin-top: 20px;
  `};
`
