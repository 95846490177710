import 'index.css'
import ReactDOM from 'react-dom'
import App from './routes/home'
import store from 'state/index'
import BrowserSupport from 'components/gates/browser-support'
import ConnectionListener from 'components/gates/ConnectionListener'
import { Provider } from 'react-redux'
import { ChainId, DAppProvider } from '@usedapp/core'
import { MemoryRouter, Switch, Route } from 'react-router-dom'
import { infuraToken } from './util/config'

const config = {
  readOnlyChain: ChainId.Mainnet,
  readOnlyUrls: {
    [ChainId.Mainnet]: `https://mainnet.infura.io/v3/${infuraToken}`,
    [ChainId.Ropsten]: `https://ropsten.infura.io/v3/${infuraToken}`
  }
}

ReactDOM.render(
  <Provider store={store}>
    <DAppProvider config={config}>
      <BrowserSupport>
        <ConnectionListener>
          <MemoryRouter>
            <Switch>
              <Route path='/' exact={true} component={App} />
            </Switch>
          </MemoryRouter>
        </ConnectionListener>
      </BrowserSupport>
    </DAppProvider>
  </Provider>,
  document.getElementById('root')
)
