import styled from 'styled-components'
import browser from 'images/browser.png'
import chrome from 'images/chrome.png'
import brave from 'images/brave.png'
import edge from 'images/edge.png'
import opera from 'images/opera.png'
import { withModifiers } from 'util/styles'

const { innerHeight } = window

export const ChromeImage = styled.img.attrs({
  src: chrome
})`
  width: 50px;
  height: 50px;
`

export const BrowserImage = styled.img.attrs({
  src: browser
})`
  width: 210px;
  height: 140px;
  margin-bottom: 30px;
`

export const BraveImage = styled.img.attrs({
  src: brave
})`
  width: 43px;
  height: 50px;
`

export const OperaImage = styled.img.attrs({
  src: opera
})`
  width: 50px;
  height: 50px;
`

export const EdgeImage = styled.img.attrs({
  src: edge
})`
  width: 50px;
  height: 50px;
`

export const Text = styled.label`
  -webkit-font-smoothing: antialiased;

  ${withModifiers('supported-browsers')`
    color: rgb(255, 255, 255);
    font-size: 11px;
    font-family: Montserrat-SemiBold;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    padding-bottom: 25px;
  `};

  ${withModifiers('browser-not-supported-title')`
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-family: Montserrat-Bold;
    font-weight: bold;
    text-align: center;
    line-height: 30px;
  `};

  ${withModifiers('browser-not-supported-description')`
    color: rgb(255, 255, 255);
    font-size: 15px;
    font-family: Adelle-Regular;
    font-weight: normal;
    text-align: center;
    line-height: 22px;
    margin-bottom: 50px;
  `};

  ${withModifiers('browser-name')`
    color: rgb(255, 255, 255);
    font-size: 11px;
    font-family: Adelle-Regular;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0px;
    line-height: 17px;
    padding-top: 7px;
  `};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const CenterContainer = styled.div`
  text-align: center;

  ${withModifiers('page')`
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  `};

  ${withModifiers('browsers')`
    flex-direction: row;
    display: flex;
    align-items: center;
    margin: 0 auto;
  `};
`

export const BrowserContainer = styled.div`
  text-align: center;
  margin-right: 30px;
  flex-direction: column;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.5;
  }
`

export const PageContainer = styled.div`
  height: ${innerHeight}px;
  position: relative;
`
