import { Button as ButtonStyle, Text } from './components'
import ReactLoading from 'react-loading'

interface ButtonInterface {
  text: string
  children?: any
  black?: boolean
  height?: number
  orange?: boolean
  purple?: boolean
  loading?: boolean
  outlined?: boolean
  disabled?: boolean
  onClick: () => void
}

const Button = ({
  text,
  black,
  purple,
  orange,
  height,
  onClick,
  loading,
  children,
  outlined,
  disabled
}: ButtonInterface) => {
  return (
    <ButtonStyle
      outlined={outlined}
      purple={purple}
      orange={orange}
      height={height}
      black={black}
      disabled={disabled}
      onClick={!disabled && !loading ? onClick : undefined}
    >
      {loading ? (
        <Text center loading='true'>
          <ReactLoading type='spin' height='26px' width='26px' />
        </Text>
      ) : (
        <Text purple={purple} disabled={disabled} orange={orange} outlined={outlined}>
          {text}
        </Text>
      )}
      {children}
    </ButtonStyle>
  )
}

export default Button
