import Button from 'components/button'
import ButtonWithIcon from 'components/button-with-icon'
import Config from 'state/config/model'
import { isAvailable } from 'util/claim'
import { amplitudeSendData } from 'util/amplitude'
import { connect } from 'react-redux'
import { actions as configActions } from 'state/config'
import { stakingUrl } from 'util/config'
import { Logo, Text, Circle, StormX, RowContainer, MetamaskLogo, CenterContainer, ButtonContainer } from './components'

declare global {
  interface Window {
    ethereum: any
  }
}

interface HeaderProps {
  wallet?: string
  setDisplayAccount: (_show: boolean) => void
  setDisplayConnectWallet: (_show: boolean) => void
}

const Header = ({ wallet, setDisplayAccount, setDisplayConnectWallet }: HeaderProps) => {
  return (
    <RowContainer header>
      <RowContainer logo>
        <StormX />
        <Logo onClick={() => window.open('http://stormx.io/')} />
      </RowContainer>

      <CenterContainer>
        <Text
          back
          onClick={() => {
            amplitudeSendData('Clicked back to stormx.io button')
            window.open('http://stormx.io/')
          }}
        >
          Back to stormx.io
        </Text>
        <Text
          back
          onClick={() => {
            window.open(stakingUrl)
          }}
        >
          Stake ATH Tokens
        </Text>

        {window.ethereum !== undefined && (
          <ButtonContainer add>
            <ButtonWithIcon
              text='Add ATH token to MetaMask'
              white={true}
              onClick={async () => {
                await window.ethereum.request({
                  method: 'wallet_watchAsset',
                  params: {
                    type: 'ERC20',
                    options: {
                      address: '0x2f9411088cef82fd9fb904eb8092f28eb485c8f6',
                      symbol: 'ATH',
                      decimals: 18,
                      image: 'https://assets.coingecko.com/coins/images/24514/small/ATH-logo-round.png?1647951491'
                    }
                  }
                })
              }}
            >
              <MetamaskLogo />
            </ButtonWithIcon>
          </ButtonContainer>
        )}

        <ButtonContainer>
          <Button
            text={wallet ? `${wallet.slice(0, 4)}...${wallet.slice(-4)}` : 'Connect wallet'}
            black={true}
            disabled={!isAvailable()}
            onClick={() => {
              if (!wallet) {
                amplitudeSendData('Clicked connect wallet button in header')
                setDisplayConnectWallet(true)
              } else {
                setDisplayAccount(true)
              }
            }}
          >
            {wallet && <Circle />}
          </Button>
        </ButtonContainer>
      </CenterContainer>
    </RowContainer>
  )
}

const enhancer = connect(
  (state: { config: Config }) => ({
    wallet: state.config.wallet
  }),
  {
    setDisplayAccount: configActions.setDisplayAccount,
    setDisplayConnectWallet: configActions.setDisplayConnectWallet
  }
)

export default enhancer(Header)
