import { checkStatus, setHeaders } from './helper'
import { baseUrl, serverUrl } from '../config'

export async function request(url, options, toServer = false) {
  const extraHeaders = await setHeaders()
  const actualUrl = `${toServer ? serverUrl : baseUrl}${url}`
  const actualOptions = {
    ...options,
    headers: {
      ...extraHeaders
    }
  }

  return fetch(actualUrl, actualOptions).then(checkStatus)
}
