import Config from 'state/config/model'
import ContractABI from 'misc/claimContractAbi.json'
import { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { ChainId, useEthers } from '@usedapp/core'
import { isDev, isStg, isProd } from 'util/config'
import { actions as configActions } from 'state/config'
import { ethers } from 'ethers'
import { claimAddress } from 'util/config'
import { isEmpty } from 'lodash'
import { amplitudeSendData, amplitudeTrackUser } from 'util/amplitude'

interface NetworkListenerProps {
  children: any
  walletInfo: object
  connectionType: string
  setWallet: (_wallet: string) => void
  setClaimed: (_claimed: boolean) => void
  getWalletInfo: (_wallet: string) => void
  getATHRate: () => void
  setDisplayNetworkError: (_show: boolean) => void
  setDisplayConnectWallet: (_show: boolean) => void
}

const NetworkListener = ({
  children,
  setWallet,
  setClaimed,
  walletInfo,
  getWalletInfo,
  getATHRate,
  connectionType,
  setDisplayNetworkError,
  setDisplayConnectWallet
}: NetworkListenerProps) => {
  const { chainId, account } = useEthers()

  useEffect(() => {
    getATHRate()
  }, [getATHRate])

  const getContract = useCallback(() => {
    return new ethers.Contract(claimAddress, ContractABI)
  }, [])

  const isClaimed = useCallback(
    async (index: number) => {
      return await getContract().isClaimed(index)
    },
    [getContract]
  )

  useEffect(() => {
    async function checkIfHasClaimed(index: number) {
      const claimed = await isClaimed(index)
      setClaimed(claimed)
    }

    if (!isEmpty(walletInfo)) {
      // @ts-ignore
      checkIfHasClaimed(walletInfo.claim.index)
    }
  }, [isClaimed, setClaimed, walletInfo])

  useEffect(() => {
    if (chainId) {
      if (isDev && chainId !== ChainId.Ropsten) {
        setDisplayNetworkError(true)
      } else if ((isStg || isProd) && chainId !== ChainId.Mainnet) {
        setDisplayNetworkError(true)
      } else {
        setDisplayNetworkError(false)
      }
    }
  }, [chainId, setDisplayNetworkError])

  useEffect(() => {
    setWallet(account ? account : '')

    if (account) {
      amplitudeTrackUser(account)
      amplitudeSendData('Selected wallet successfully connected', { wallet: connectionType })
      getWalletInfo(account)
      setDisplayConnectWallet(false)
    }
  }, [account, setWallet, getWalletInfo, connectionType, setDisplayConnectWallet])

  return children
}

const enhancer = connect(
  (state: { config: Config }) => ({
    walletInfo: state.config.walletInfo,
    connectionType: state.config.connectionType
  }),
  {
    setWallet: configActions.setWallet,
    setClaimed: configActions.setClaimed,
    getWalletInfo: configActions.getWalletInfo,
    getATHRate: configActions.getATHRate,
    setDisplayNetworkError: configActions.setDisplayNetworkError,
    setDisplayConnectWallet: configActions.setDisplayConnectWallet
  }
)

export default enhancer(NetworkListener)
