import { Fragment } from 'react'
import { Text, Button as ButtonStyle } from './components'
import ReactLoading from 'react-loading'

interface ButtonInterface {
  text: string
  children: any
  height?: number
  white?: boolean
  loading?: boolean
  onClick: () => void
}

const Button = ({ text, loading, onClick, children, white, height }: ButtonInterface) => {
  return (
    <ButtonStyle white={white} height={height} loading={loading} onClick={loading ? undefined : onClick}>
      {loading ? (
        <ReactLoading type='spin' height='26px' width='26px' />
      ) : (
        <Fragment>
          {children}
          <Text white={white}>{text}</Text>
        </Fragment>
      )}
    </ButtonStyle>
  )
}

export default Button
