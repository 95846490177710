import ReactModal from 'react-modal'
import Config from 'state/config/model'
import Button from 'components/button'
import Confetti from 'react-confetti'
import { ethers } from 'ethers'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'
import { actions as configActions } from 'state/config'
import { stakingUrl } from 'util/config'
import { Logo, Text, AmountContainer, ButtonContainer, ConfettiContainer } from './components'

interface ModalErrorProps {
  walletInfo: object
  displaySuccessModal: boolean
  setDisplaySuccess: (_show: boolean) => void
}

ReactModal.setAppElement('#root')

const ModalSuccess = ({ walletInfo, displaySuccessModal, setDisplaySuccess }: ModalErrorProps) => {
  return (
    <ReactModal
      isOpen={displaySuccessModal}
      contentLabel='onRequestClose Example'
      onRequestClose={() => undefined}
      className='Modal-Container'
      style={{
        content: {
          marginTop: '15%'
        }
      }}
      overlayClassName='Overlay-Modal'
    >
      <Logo />
      <Text success>You’ve claimed:</Text>

      <AmountContainer>
        {/*// @ts-ignore */}
        <Text tokens>{isEmpty(walletInfo) ? 0 : ethers.utils.formatUnits(walletInfo.claim.amount, 18)} tokens</Text>
      </AmountContainer>

      <ButtonContainer grid>
        <Button
          text='Close'
          purple={true}
          onClick={() => {
            setDisplaySuccess(false)
          }}
        />
        <Button
          text='Stake ATH Tokens'
          onClick={() => {
            window.open(stakingUrl)
          }}
        />
      </ButtonContainer>

      <ConfettiContainer>
        <Confetti width={530} height={280} numberOfPieces={50} />
      </ConfettiContainer>
    </ReactModal>
  )
}

const enhancer = connect(
  (state: { config: Config }) => ({
    walletInfo: state.config.walletInfo,
    displaySuccessModal: state.config.displaySuccessModal
  }),
  {
    setDisplaySuccess: configActions.setDisplaySuccess
  }
)

export default enhancer(ModalSuccess)
