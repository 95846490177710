import styled from 'styled-components'
import { color } from 'styles'
import { withModifiers } from 'util/styles'

export const Button = styled.button`
  width: 100%;
  background: ${color.ui.accentPrimary};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 0 rgba(44, 37, 60, 0.1);
  border-radius: 22px;
  height: ${(props) => (props.height ? `${props.height}px` : '44px')};
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  border: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  background: ${color.brand.purple};
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  ${withModifiers('loading')`  
    display: flex;
    justify-content: center;
    align-items: center;
    
    &:hover {
      opacity: 1;
    }
  `};

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  ${withModifiers('white')`
    background: white;
  `};
`

export const Text = styled.label`
  -webkit-font-smoothing: antialiased;
  width: 216px;
  color: rgb(254, 255, 255);
  font-size: 14px;
  font-family: Montserrat-Bold;
  text-align: center;
  line-height: 18px;
  cursor: pointer;

  ${withModifiers('white')`
    color: rgb(253, 46, 83);
  `};
`
