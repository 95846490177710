import styled from 'styled-components'
import { withModifiers } from 'util/styles'

export const Container = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin-top: 50px;
  margin-bottom: 20px;
`

export const Text = styled.label`
  -webkit-font-smoothing: antialiased;
  margin-bottom: 0px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-family: Adelle-Regular;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0px;
  line-height: 20px;
  opacity: 0.6;

  ${withModifiers('link')`
    &:hover {
      opacity: 0.5;
    }
    
    cursor: pointer;
  `};
`
