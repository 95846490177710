import { Text, Container } from './components'

const Footer = () => {
  return (
    <Container>
      <Text>© Copyright {new Date().getFullYear()} StormX Singapore PTE LTD.</Text>
      <label>
        <Text link onClick={() => window.open('https://stormx.io/terms-of-use')}>
          Terms Of Use
        </Text>
        <Text> | </Text>
        <Text link onClick={() => window.open('https://stormx.io/privacy-policy')}>
          Privacy Policy
        </Text>
      </label>
    </Container>
  )
}

export default Footer
