import styled from 'styled-components'
import close from 'images/close.png'
import copy from 'images/copy.png'
import { withModifiers } from 'util/styles'

export const CloseIcon = styled.img.attrs({
  src: close
})`
  width: 32px;
  height: 32px;
  float: right;
  margin-top: -5px;
  margin-left: -32px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`

export const CopyIcon = styled.img.attrs({
  src: copy
})`
  height: 24px;
  width: 24px;
  cursor: pointer;
  margin-right: 5px;

  &:hover {
    opacity: 0.5;
  }
`

export const Text = styled.label`
  -webkit-font-smoothing: antialiased;
  margin-bottom: 0px;

  ${withModifiers('modal-title')`
    text-align: left;
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-family: Montserrat-ExtraBold;
    font-weight: 800;
  `};

  ${withModifiers('wallet')`
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-family: SFMono-Light;
    font-weight: 300;
  `};

  ${withModifiers('connected-with')`
    color: rgb(255, 255, 255);
    font-size: 15px;
    font-family: Adelle-Regular;
    font-weight: normal;
    line-height: 22px;
    padding-bottom: 8px;
  `};

  ${withModifiers('copy')`
    color: rgb(255, 255, 255);
    font-size: 11px;
    font-family: Montserrat-SemiBold;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  `};
`

export const Card = styled.div`
  max-width: 100%;
  background: #131313;
  border-radius: 24px;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
`

export const CardContainer = styled.div`
  margin: 0 auto;
  max-width: 700px;
  background: linear-gradient(#2b2b2b, #181818);
  border-radius: 24px;
  padding: 1px;
  margin-top: 20px;
  margin-bottom: 10px;
`

export const ColumnContainer = styled.div`
  flex-direction: column;
  display: flex;
`

export const ButtonContainer = styled.div`
  width: 130px;
`

export const RowContainer = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
  padding-top: 15px;
`
