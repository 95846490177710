import styled from 'styled-components'
import logo from 'images/ath.png'
import { withModifiers } from 'util/styles'

export const Logo = styled.img.attrs({
  src: logo
})`
  width: 48px;
  height: 48px;
  display: flex;
  margin: 0 auto;
  margin-bottom: 35px;
`

export const Text = styled.label`
  -webkit-font-smoothing: antialiased;
  margin-bottom: 0px;

  ${withModifiers('success')`
    color: rgb(255, 255, 255);
    font-size: 11px;
    font-family: Montserrat-SemiBold;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    display: flex;
    opacity: 0.6;
    justify-content: center;
  `};

  ${withModifiers('tokens')`
    color: rgb(255, 255, 255);
    font-size: 26px;
    font-family: SFMono-Medium;
    font-weight: 500;
    display: flex;
    justify-content: center;
  `};
`

export const AmountContainer = styled.div`
  margin-top: 15px;
`

export const ButtonContainer = styled.div`
  width: 200px;
  margin: 0 auto;
  margin-top: 35px;

  ${withModifiers('grid')`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    width: unset;

    button {
      padding: 10px 20px;
    }
  `};
`

export const ConfettiContainer = styled.div`
  position: absolute;
  margin-top: -250px;
  margin-left: -30px;
`
