import styled from 'styled-components'
import check1 from 'images/check-1.png'
import check2 from 'images/check-2.png'
import check3 from 'images/check-3.png'
import check4 from 'images/check-4.png'
import check5 from 'images/check-5.png'
import check6 from 'images/check-6.png'
import PlayCirlceImage from 'images/icon-play-circle.svg'
import tokenLogo from 'images/ath.png'
import { withModifiers } from 'util/styles'

export const TokenLogo = styled.img.attrs({
  src: tokenLogo
})`
  width: 48px;
  height: 48px;
  margin-right: 20px;

  ${withModifiers('small')`
    width: 20px;
    height: 20px;
    margin-right: 8px;
    margin-left: 8px;
    margin-bottom: -4px;
  `};
`

export const Check1 = styled.img.attrs({
  src: check1
})`
  height: 20px;
  width: 20px;
  margin-right: 19px;
  margin-top: -6px;
`

export const Check2 = styled.img.attrs({
  src: check2
})`
  height: 20px;
  width: 20px;
  margin-right: 19px;
  margin-top: -6px;
`

export const Check3 = styled.img.attrs({
  src: check3
})`
  height: 20px;
  width: 20px;
  margin-right: 19px;
  margin-top: -6px;
`

export const Check4 = styled.img.attrs({
  src: check4
})`
  height: 20px;
  width: 20px;
  margin-right: 19px;
  margin-top: -6px;
`

export const Check5 = styled.img.attrs({
  src: check5
})`
  height: 20px;
  width: 20px;
  margin-right: 19px;
  margin-top: -6px;
`

export const Check6 = styled.img.attrs({
  src: check6
})`
  height: 20px;
  width: 20px;
  margin-right: 19px;
`

export const RowContainer = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;

  ${withModifiers('column')`
    flex-direction: column;
    align-items: flex-start;
  `};

  ${withModifiers('consent')`
    margin-top: 25px;
  `};
`

export const Text = styled.label`
  -webkit-font-smoothing: antialiased;
  margin-bottom: 0px;

  ${withModifiers('claim')`
    line-height: 77px;
    font-family: Montserrat-ExtraBold;
    font-size: 50px;
    color: #ff8971;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  `};

  ${withModifiers('description')`
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-family: Adelle-Regular;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 34px;
    text-align: center;
    display: block;
    margin-left: 30%;
    margin-right: 30%;
  `};

  ${withModifiers('add-margin-bottom')`
    margin-bottom: 50px;
  `};

  ${withModifiers('add-margin-top')`
    margin-top: 10px;
  `};

  ${withModifiers('card')`
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-family: Adelle-Regular;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 34px;
  `};

  ${withModifiers('underline')`
    text-decoration-line: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  `};

  ${withModifiers('learn-more')`
    font-family: Adelle-Regular;
    font-size: 15px;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    text-decoration: none;
    margin: 20px auto;
    display: table;
    color: rgb(255, 255, 255);
    text-decoration: underline;
    text-decoration-color: #ffffff20;
    text-underline-offset: 5px;
    text-decoration-thickness: 1px;

    &:after {
      content: "";
      display: block;
      height: 2px;
      width: 100%;
      margin-top: 1px;
    }
  `};

  ${withModifiers('claim-video')`
    background: transparent url(${PlayCirlceImage}) 0 0 no-repeat;
    color: rgb(255, 255, 255);
    font-family: Adelle-Regular;
    font-size: 15px;
    justify-content: center;
    margin: 20px auto 150px;
    display: table;
    padding-left: 30px;
    padding-top: 4px;
    text-decoration: none;
  `};

  ${withModifiers('how')`
    color: rgb(255, 255, 255);
    font-size: 11px;
    font-family: Montserrat-SemiBold;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    text-align: center;
    display: flex;
    justify-content: center;
    opacity: 0.6;
    margin-top: 60px;
  `};

  ${withModifiers('lock')`
    margin-top: 10px;

    ${withModifiers('connected')`
      margin-top: -40px;
      margin-bottom: 50px;
    `};
  `};

  ${withModifiers('eligible')`
    color: rgb(255, 255, 255);
    font-size: 11px;
    font-family: Montserrat-SemiBold;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
  `};

  ${withModifiers('amount')`
    color: rgb(255, 255, 255);
    font-size: 26px;
    font-family: SFMono-Medium;
    font-weight: 500;
    margin-top: 9px;
  `};

  ${withModifiers('connect-different-wallet')`
    color: rgb(255, 255, 255);
    font-size: 15px;
    font-family: Adelle-Regular;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0px;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  `};

  ${withModifiers('consent')`
    color: rgb(255, 255, 255);
    font-size: 15px;
    font-family: Adelle-Regular;
    font-weight: normal;
    line-height: 22px;
  `};
`

export const ConnectContainer = styled.div`
  margin-top: 15px;
`

export const ButtonContainer = styled.div`
  width: 260px;
  margin: 0 auto;

  ${withModifiers('eligible')`
    width: 200px;
    margin: 0;
    text-align: center;
  `};
`

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: center;
  align-items: center;
`

export const Card = styled.div`
  max-width: 100%;
  background: #131313;
  border-radius: 24px;
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
`

export const CardContainer = styled.div`
  margin: 0 auto;
  max-width: 700px;
  background: linear-gradient(#2b2b2b, #181818);
  border-radius: 24px;
  padding: 1px;
  margin-top: 20px;

  ${withModifiers('eligible')`
    margin-bottom: 50px;
  `};
`

export const StepContainer = styled.div`
  margin-top: 30px;
  margin-left: 25px;
`

export const StepItem = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
  margin-top: 12px;
`

export const ConsentContainer = styled.div`
  margin-left: 8px;
`
