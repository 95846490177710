export const baseUrl = process.env.REACT_APP_API_URL as string
export const serverUrl = process.env.REACT_APP_SERVER_URL as string
export const stakingUrl = process.env.REACT_APP_STAKING_URL as string
export const isDev = baseUrl.includes('dev') as boolean
export const isStg = baseUrl.includes('stg') as boolean
export const isProd = !baseUrl.includes('dev') && (!baseUrl.includes('stg') as boolean)
export const infuraToken = process.env.REACT_APP_INFURA_TOKEN as string
export const claimAddress = process.env.REACT_APP_CLAIM_ADDRESS as string
export const startDate = new Date('2022-03-22T23:15:00') as Date
export const endDate = new Date('2022-04-12T00:00:00') as Date
