import ReactModal from 'react-modal'
import Config from 'state/config/model'
import { connect } from 'react-redux'
import { Text } from './components'
import { isDev } from 'util/config'

interface ModalNetworkErrorProps {
  isNetworkWrong: boolean
}

ReactModal.setAppElement('#root')

const ModalNetworkError = ({ isNetworkWrong }: ModalNetworkErrorProps) => {
  return (
    <ReactModal
      isOpen={isNetworkWrong}
      contentLabel='onRequestClose Example'
      onRequestClose={() => undefined}
      className='Modal-Container'
      style={{
        content: {
          marginTop: '20%'
        }
      }}
      overlayClassName='Overlay-Modal'
    >
      <div>
        <Text modal-title>{`Switch to ${isDev ? 'Ropsten' : 'Mainnet'} network`}</Text>
      </div>
    </ReactModal>
  )
}

const enhancer = connect(
  (state: { config: Config }) => ({
    isNetworkWrong: state.config.isNetworkWrong
  }),
  {}
)

export default enhancer(ModalNetworkError)
