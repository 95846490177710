import styled from 'styled-components'
import logo from 'images/logo-white.png'
import stormx from 'images/stormx.png'
import metamaskLogo from 'images/metamask.png'
import { withModifiers } from 'util/styles'

export const Logo = styled.img.attrs({
  src: logo
})`
  height: 18px;
  width: 80px;
  cursor: pointer;
  margin-right: 25px;

  &:hover {
    opacity: 0.5;
  }
`

export const MetamaskLogo = styled.img.attrs({
  src: metamaskLogo
})`
  height: 28px;
  width: 28px;
  margin-right: 8px;
`

export const StormX = styled.img.attrs({
  src: stormx
})`
  width: 48px;
  height: 48px;
  margin-right: 10px;

  &:hover {
    opacity: 0.5;
  }
`

export const Text = styled.label`
  -webkit-font-smoothing: antialiased;
  margin-bottom: 0px;
  cursor: pointer;

  ${withModifiers('back')`
    font-family: Adelle-Regular;
    font-size: 15px;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    text-decoration: none;
    display: table;
    color: rgb(255, 255, 255);
    margin-right: 15px;
    text-decoration: underline;
    text-decoration-color: #ffffff20;
    text-underline-offset: 5px;
    text-decoration-thickness: 1px;

    &:after {
      content: "";
      display: block;
      height: 2px;
      width: 100%;
      background: rgba(white, .2);
      margin-top: 1px;
    }

    &:hover {
      opacity: 0.5;
    }
  `};
`

export const RowContainer = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin-bottom: 45px;
  margin-left: 130px;
  margin-right: 130px;

  @media only screen and (max-width: 700px) {
    margin-left: 0px;
    margin-right: 0px;
  }

  @media only screen and (max-width: 1000px) {
    margin-left: 10px;
    margin-right: 10px;
  }

  ${withModifiers('logo')`  
    justify-content: center;
    display: flex;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
  `};

  ${withModifiers('header')`  
    margin-top: 28px;
  `};
`

export const CloseContainer = styled.div`
  width: 44px;
  height: 44px;
`

export const ButtonContainer = styled.div`
  width: 175px;
  margin-left: 10px;

  ${withModifiers('add')`
    width: 330px;
  `};
`

export const Circle = styled.div`
  height: 6px;
  width: 6px;
  background: rgb(23, 229, 121);
  border-radius: 33px;
  margin-left: 15px;
`

export const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
