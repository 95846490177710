import ReactModal from 'react-modal'
import Config from 'state/config/model'
import Button from 'components/button'
import { connect } from 'react-redux'
import { useEthers } from '@usedapp/core'
import { actions as configActions } from 'state/config'
import { amplitudeSendData } from 'util/amplitude'
import {
  Text,
  Card,
  CopyIcon,
  CloseIcon,
  RowContainer,
  CardContainer,
  ButtonContainer,
  ColumnContainer
} from './components'

ReactModal.setAppElement('#root')

interface ModalConnectProps {
  wallet: string
  connectionType: string
  displayAccountModal: boolean
  setDisplayAccount: (_show: boolean) => void
}

const ModalConnect = ({ wallet, connectionType, setDisplayAccount, displayAccountModal }: ModalConnectProps) => {
  const { deactivate } = useEthers()

  return (
    <ReactModal
      isOpen={displayAccountModal ? displayAccountModal : false}
      contentLabel='onRequestClose Example'
      onRequestClose={() => setDisplayAccount(!displayAccountModal)}
      className='Modal-Container'
      overlayClassName='Overlay-Modal'
    >
      <div>
        <Text modal-title>Account</Text>
        <CloseIcon onClick={() => setDisplayAccount(!displayAccountModal)} />
      </div>

      <CardContainer>
        <Card>
          <ColumnContainer>
            <Text connected-with>Connected with {connectionType}</Text>
            <Text wallet>{`${wallet.slice(0, 6)}...${wallet.slice(-4)}`}</Text>

            <RowContainer onClick={() => navigator.clipboard.writeText(wallet)}>
              <CopyIcon />
              <Text copy>Copy address</Text>
            </RowContainer>
          </ColumnContainer>

          <ButtonContainer>
            <Button
              height={34}
              text='Disconnect'
              orange={true}
              onClick={() => {
                amplitudeSendData('Clicked disconnect wallet button', { wallet })
                setDisplayAccount(false)
                deactivate()
              }}
            />
          </ButtonContainer>
        </Card>
      </CardContainer>
    </ReactModal>
  )
}

const enhancer = connect(
  (state: { config: Config }) => ({
    wallet: state.config.wallet,
    connectionType: state.config.connectionType,
    displayAccountModal: state.config.displayAccountModal
  }),
  {
    setDisplayAccount: configActions.setDisplayAccount
  }
)

export default enhancer(ModalConnect)
