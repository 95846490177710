import styled from 'styled-components'
import { color } from 'styles'
import { withModifiers } from 'util/styles'

export const Button = styled.button`
  width: 100%;
  background: ${color.ui.accentPrimary};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 0 rgba(44, 37, 60, 0.1);
  border-radius: 22px;
  height: ${(props) => (props.height ? `${props.height}px` : '44px')};
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  border: none;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;

  &:hover {
    opacity: 0.5;
  }

  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }

  ${withModifiers('purple')`
    background: ${color.brand.purple};
  `};

  ${withModifiers('orange')`
    background-color: transparent;
    border-color: rgb(255, 137, 113);
    border-width: 1px;
    border-style: solid;
    justify-content: center;
  `};

  ${withModifiers('black')`
    background: #0b0b0b;
  `};

  ${withModifiers('outlined')`
    background-color: transparent;
    border-color: ${color.brand.pinkred};
    border-width: 1px;
    border-style: solid;
    justify-content: center;

    ${withModifiers('purple')`
      border-color: ${color.brand.purple};
      border: 0.5px solid rgba(86, 96, 105, 0.3);
      box-shadow: none !important;
    `};
  `};

  ${withModifiers('disabled')`
    background: rgba(255, 255, 255, 0.05);
    border-color: rgba(255, 255, 255, 0.05);

    &:hover {
      opacity: 1;
    }
  `};
`

export const Text = styled.label`
  -webkit-font-smoothing: antialiased;
  color: rgb(254, 255, 255);
  font-size: 14px;
  font-family: Montserrat-Bold;
  font-weight: bold;
  text-align: center;
  line-height: 18px;
  cursor: pointer;
  margin-bottom: 0px;

  ${withModifiers('purple')`
    color: white;
  `};

  ${withModifiers('orange')`
    color: rgb(255, 137, 113);
  `};

  ${withModifiers('outlined')`
    color: rgb(253, 46, 83);
    font-size: 14px;    
    text-align: center;
    line-height: 18px;

    ${withModifiers('purple')`
      color: rgb(43, 41, 105);
    `};
  `};

  ${withModifiers('disabled')`
    color: rgb(254, 255, 255, 30);
    opacity: 0.3;
  `};
`
