import ReactModal from 'react-modal'
import Config from 'state/config/model'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { Text, Line, CloseIcon } from './components'
import { actions as configActions } from 'state/config'

interface ModalErrorProps {
  error: string
  displayErrorModal: boolean
  setError: (_error: string) => void
  setDisplayError: (_show: boolean) => void
}

ReactModal.setAppElement('#root')

const ModalError = ({ error, setError, displayErrorModal, setDisplayError }: ModalErrorProps) => {
  return (
    <ReactModal
      isOpen={displayErrorModal && !isEmpty(error)}
      contentLabel='onRequestClose Example'
      onRequestClose={() => undefined}
      className='Modal-Container'
      style={{
        content: {
          marginTop: '5%'
        }
      }}
      overlayClassName='Overlay-Modal'
    >
      <div>
        <Text modal-title>Error</Text>
        <CloseIcon
          onClick={() => {
            setError('')
            setDisplayError(false)
          }}
        />
      </div>

      <Line />

      <Text modal-description>{error}</Text>
    </ReactModal>
  )
}

const enhancer = connect(
  (state: { config: Config }) => ({
    error: state.config.error,
    displayErrorModal: state.config.displayErrorModal
  }),
  {
    setError: configActions.setError,
    setDisplayError: configActions.setDisplayError
  }
)

export default enhancer(ModalError)
