import { createActionThunk } from 'util/redux'
import { createAction } from 'redux-actions'
import * as network from './network'

export const getWalletInfo = createActionThunk('CONFIG_GET_WALLET_INFO', network.getWalletInfo)

export const getATHRate = createActionThunk('ATH_RATE', network.getATHRate)

export const setError = createAction('CONFIG_SET_ERROR')
export const setWallet = createAction('CONFIG_SET_WALLET')
export const setClaimed = createAction('CONFIG_SET_CLAIMED')
export const setDisplayAccount = createAction('CONFIG_DISPLAY_ACCOUNT')
export const setDisplayError = createAction('CONFIG_SET_DISPLAY_ERROR')
export const setDisplaySuccess = createAction('CONFIG_SET_DISPLAY_SUCCESS')
export const setConnectionType = createAction('CONFIG_SET_CONNECTION_TYPE')
export const setDisplayNetworkError = createAction('CONFIG_DISPLAY_NETWORK_ERROR')
export const setDisplayConnectWallet = createAction('CONFIG_SET_DISPLAY_CONNECT_WALLET')
