import ReactModal from 'react-modal'
import Config from 'state/config/model'
import { isDev } from 'util/config'
import { connect } from 'react-redux'
import { infuraToken } from 'util/config'
import { ChainId, useEthers } from '@usedapp/core'
import { amplitudeSendData } from 'util/amplitude'
import { actions as configActions } from 'state/config'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { Text, CloseIcon, CoinbaseIcon, MetamaskIcon, ButtonContainer, WalletConnectIcon } from './components'

ReactModal.setAppElement('#root')

interface ModalConnectProps {
  displayConnectWalletModal: boolean
  setConnectionType: (_type: string) => void
  setDisplayConnectWallet: (_show: boolean) => void
}

const walletConnect = new WalletConnectConnector({
  rpc: {
    [ChainId.Mainnet]: `https://mainnet.infura.io/v3/${infuraToken}`,
    [ChainId.Ropsten]: `https://ropsten.infura.io/v3/${infuraToken}`
  },
  qrcode: true
})

const walletLink = new WalletLinkConnector({
  url: `https://${isDev ? 'ropsten' : 'mainnet'}.infura.io/v3/${infuraToken}`,
  appName: 'StormX',
  supportedChainIds: [ChainId.Mainnet, ChainId.Ropsten]
})

const ModalConnect = ({ setConnectionType, setDisplayConnectWallet, displayConnectWalletModal }: ModalConnectProps) => {
  const { activateBrowserWallet, activate } = useEthers()

  const activateWalletConnect = () => {
    activate(walletConnect)
  }

  const activateWalletLink = () => {
    activate(walletLink)
  }

  return (
    <ReactModal
      isOpen={displayConnectWalletModal ? displayConnectWalletModal : false}
      contentLabel='onRequestClose Example'
      onRequestClose={() => setDisplayConnectWallet(!displayConnectWalletModal)}
      className='Modal-Container'
      overlayClassName='Overlay-Modal'
    >
      <div>
        <Text modal-title>Connect a wallet</Text>
        <CloseIcon onClick={() => setDisplayConnectWallet(!displayConnectWalletModal)} />
      </div>

      <ButtonContainer
        metamask
        onClick={async () => {
          try {
            amplitudeSendData('Selected a wallet on the connect wallet popup', {
              wallet: 'MetaMask'
            })
            setConnectionType('MetaMask')
            await activateBrowserWallet(undefined)
          } catch (error) {
            window.open('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn')
          }
        }}
      >
        <Text wallet>MetaMask</Text>
        <MetamaskIcon />
      </ButtonContainer>

      <ButtonContainer
        onClick={() => {
          amplitudeSendData('Selected a wallet on the connect wallet popup', {
            wallet: 'WalletConnect'
          })
          setConnectionType('WalletConnect')
          activateWalletConnect()
        }}
      >
        <Text wallet>WalletConnect</Text>
        <WalletConnectIcon />
      </ButtonContainer>

      <ButtonContainer
        onClick={() => {
          amplitudeSendData('Selected a wallet on the connect wallet popup', {
            wallet: 'Coinbase Wallet'
          })
          setConnectionType('Coinbase Wallet')
          activateWalletLink()
        }}
      >
        <Text wallet>Coinbase Wallet</Text>
        <CoinbaseIcon />
      </ButtonContainer>
    </ReactModal>
  )
}

const enhancer = connect(
  (state: { config: Config }) => ({
    displayConnectWalletModal: state.config.displayConnectWalletModal
  }),
  {
    setConnectionType: configActions.setConnectionType,
    setDisplayConnectWallet: configActions.setDisplayConnectWallet
  }
)

export default enhancer(ModalConnect)
