import moment from 'moment'
import { startDate, endDate } from './config'

export const isAvailable = () => {
  const date = new Date()
  const currentUtcTime = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )

  const isAfterStart = moment(currentUtcTime).isAfter(startDate)
  const isBeforeEnd = moment(currentUtcTime).isBefore(endDate)
  return isAfterStart && isBeforeEnd
}
