import styled from 'styled-components'
import close from 'images/close.png'
import { withModifiers } from 'util/styles'

export const CloseIcon = styled.img.attrs({
  src: close
})`
  width: 32px;
  height: 32px;
  float: right;
  margin-top: -5px;
  margin-left: -32px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`

export const Text = styled.label`
  -webkit-font-smoothing: antialiased;
  margin-bottom: 0px;

  ${withModifiers('modal-title')`
    text-align: left;
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-family: Montserrat-ExtraBold;
    font-weight: 800;
  `};

  ${withModifiers('modal-description')`
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-family: Adelle-Regular;
    line-height: 20px;
    word-wrap: break-word;
  `};
`

export const Line = styled.div`
  background: #2b2b2b;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
`
