import { request } from 'util/network'

export async function getWalletInfo(wallet: string) {
  const data = await request(`/?wallet=${wallet}`)
  return data
}

export async function getATHRate() {
  const { rate } = await request(`/staking/rate?token=athens`, null, true)
  return rate
}
