export const brand = {
  pinkredLight: '#ffbfd0',
  pinkred: '#fd2e53',
  purple: '#6500c9',
  purpleLight: '#bda5f8'
}

export const neutral = {
  black: '#2c243c',
  grayLightest: '#fafafa',
  grayLighter: '#f4f4f5',
  grayLight: '#e8e7ee',
  gray: '#ccc9d3',
  grayDark: '#746f7f',
  grayDarker: '#2c253c',
  grayDarkest: '#2c243d',
  white: '#ffffff'
}

export const ui = {
  accentPrimary: brand.pinkred,
  accentSecondary: brand.pinkred,
  danger: '#d50000',
  success: '#4ecebe',
  textLight: neutral.gray,
  text: neutral.grayDark,
  textDark: neutral.grayDarker,
  orange: '#fd9d1a',
  yellow: '#eeb73f',
  warning: '#fffabf'
}
